<style>
.v-dialog__content .v-card__title {
    background-color: white !important;
    color: black !important;
}

</style>
<template>
<!--eslint-disable-->
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="justify-content-center" style="padding-bottom: 0 !important; flex-direction: column;">
          <svg class="my-3" width="78.055" height="68" viewBox="0 0 78.055 68">
            <g id="alert-triangle" transform="translate(0.536 -0.897)">
              <path id="Trazado_40929" data-name="Trazado 40929" d="M32.444,6.3,2.5,56.291A7.071,7.071,0,0,0,8.545,66.9H68.434A7.071,7.071,0,0,0,74.48,56.291L44.535,6.3a7.071,7.071,0,0,0-12.091,0Z" transform="translate(0 0)" fill="none" stroke="#fdd008" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
              <line id="Línea_324" data-name="Línea 324" y2="14.382" transform="translate(38.49 24.369)" fill="none" stroke="#fdd008" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
              <line id="Línea_325" data-name="Línea 325" x2="0.036" transform="translate(38.489 52.756)" fill="none" stroke="#fdd008" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
            </g>
          </svg>
          <span class="fs20 bold">
            {{ $t('ensayos.delete_title') }}
          </span>
          </v-card-title>

        <v-card-text class="fs16 my-3" style="padding-top: 0 !important;">
          {{ $t('ensayos.delete_cuestion') }} <b>{{ensayonombre}}</b> ? <br>
          {{ $t('ensayos.delete_cuestion2') }}
        </v-card-text>

        <v-card-actions class="justify-content-center pb-3">
            <v-btn
              class="c-btn mb-3"
              color="#007AFF"
              outlined
              @click="dialog = false"
            >
              {{ $t('ensayos.cancelar') }}
            </v-btn>

             <v-btn
              class="c-btn c-btn--blue bold mb-3"
              outlined
              @click="deleteE()"
            >
              {{ $t('analisis.aceptar') }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Service from '../../services/apis';

export default {
  props: {
    Father: Object,
  },
  data() {
    return {
      dialog: false,
      ensayonombre: null,
      item: null,
      loading: false,
    };
  },
  methods: {
    open(item) {
      this.dialog = true;
      this.item = item;
      this.ensayonombre = this.item.ensa_informe;
    },
    close() {
      this.dialog = false;
    },
    async deleteE() {
      // this.Father.deleteEnsayo();
      this.loading = true;
      const deletedTest = await Service.apiToken('POST', 'delete-test', this.$ls.storage.token, this.item);
      if (deletedTest) {
        this.Father.init();
      }
      this.item = null;
      this.loading = false;
      this.close();
    },
  },
};
</script>
